module.exports = {
  common: {},
  swap: {
    ethInfo: "您的ETH钱包信息",
    bscInfo: "您的BSC钱包信息",
    Balance: "DPR余额",
    transferInfo: "转账信息",
    DeeperChain: "Deeper Chain 钱包地址",
    inputDeeperChain: "请输入DeeperChain钱包地址",
    ethAddress: "ETH 钱包地址",
    inputEthAddress: "请输入ETH 钱包地址",
    bscAddress: "BSC 钱包地址",
    inputBscAddress: "请输入BSC 钱包地址",
    Send: "发送交易",
    SendTips: "全网单个方向每天最多跨链 1,000,000 DPR",
    SendTips2: "每一次跨链需要12次区块验证。",
    deeperChainInfo: "您的DeeperChain钱包信息",
    canWithdrawBalance: "当前可提现DPR",
    withdraw: "提现",
    time: "时间",
    direction: "方向",
    sender: "发送账户",
    recipient: "接收账户",
    amount: "数量",
    status: "状态",
    success: "成功",
    pending: "进行中",
    withdrawed: "已提现",
    recordList: "转账记录",
    error: "失败",
    noPolkadot: "请安装polkadot{.js} extension chrome插件，并导入钱包。",
    noMetamask: "请安装 Metamask 插件",
    testnetError: "您正在使用测试网，请切换到正式网。",
    usedToday: "当前时间段已跨链",
    max: "最多",
    min: "最少",
  },
  "Ethereum Bridge": "Ethereum Bridge",
  recommendBSC: "点击下面按钮切换到BSC网络或者Solana网络，更省手续费。",
  bscURL:
    "https://academy.binance.com/zh/articles/connecting-metamask-to-binance-smart-chain",
  "The quota for this period has been exhausted. You can wait a few hours before applying again. Your current withdrawal is likely to fail. Would you like to proceed?":
    "这个阶段的额度已经用完，您可以等待几个小时之后再次申请，您的这次提现很可能失败，是否要继续？",
  Continue: "继续",
  Cancel: "取消",
};
