<template>
  <div id="app">
    <div style="position: relative; top: 0; width: 100%; z-index: 999">
      <Navbar></Navbar>
    </div>
    <div class="blueBg">
      <div class="logoBox">
        <img :src="require('@/assets/logo-bg.png')" alt="" />
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html {
  height: 100%;
  background: #f1f1f1;
}
#app {
  background: #f1f1f1;
}
</style>

<style lang="less" scoped>
.blueBg {
  position: absolute;
  top: 0px;
  height: 500px;
  width: 100%;
  background-image: linear-gradient(#2c7bfd, #2c7bfd, #f1f1f1);
  z-index: 0;
  .logoBox {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
