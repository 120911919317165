import Vue from 'vue'
import Web3 from 'web3'
import App from './App.vue'
import router from './router'
import moment from "moment";
import VueI18n from 'vue-i18n'
import '@/theme/main.less'
import fetch from './fetch'
import DPRNavbar from '@/components/Navbar/Navbar'

import {
  Button,
  Dialog,
  Cell,
  CellGroup,
  NavBar,
  Field,
  Locale,
  NoticeBar,
  Icon,
  Popup,
  Toast,
  CountDown,
  Form,
  Tag,
  Notify,
  Tabs,
  Tab,
  Collapse, 
  CollapseItem,
  Picker,
  Loading,
  Stepper,
  Pagination
} from "vant";

import enUS from "vant/es/locale/lang/en-US";
import zhCN from "vant/es/locale/lang/zh-CN";


const components = {
  Button,
  Dialog,
  Cell,
  CellGroup,
  NavBar,
  Field,
  Locale,
  NoticeBar,
  Icon,
  Popup,
  Toast,
  CountDown,
  Form,
  Tag,
  Notify,
  Tabs,
  Tab,
  Collapse, 
  CollapseItem,
  Picker,
  Loading,
  Stepper,
  Pagination
}
Object.values(components).forEach(comp => {
  Vue.use(comp)
})
window.Web3 = Web3

Vue.use(VueI18n)


Vue.prototype.$moment = moment;
Vue.prototype.$Dialog = Dialog
Vue.prototype.$Notify = Notify
Vue.prototype.$Toast = Toast

window.$Dialog = Dialog
window.$Toast = Toast

Vue.prototype.$fetch = fetch
Vue.prototype.$sleep = function(time) {
  return new Promise(resolve => setTimeout(resolve, time)) 
}

Vue.component('Navbar', DPRNavbar)

Vue.config.productionTip = false


const i18n = new VueI18n({
  locale: (function() {
    if (window.navigator.language.match(/cn/ig)) {
      return 'cn'
    }
    return 'en'
  })(), // 设置地区
  messages: {
    cn: require('./lang/cn'),
    en: require('./lang/en'),
  },
})
Vue.prototype.$$i18n = i18n
window.$$i18n = i18n
new Vue({
  i18n,
  router,
  mounted() {
    if (window.navigator.language.match(/cn/ig)) {
      Locale.use('zh-CN', zhCN);
    } else {
      Locale.use('en-US', enUS);
    }
    this.$watch('$i18n.locale', (newVal) => {
      if (newVal === 'cn') {
        Locale.use('zh-CN', zhCN);
      } else {
        Locale.use('en-US', enUS);
      }
    })
  },
  render: h => h(App),
}).$mount('#app')
