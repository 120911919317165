module.exports = {
  swap: {
    ethInfo: "ETH wallet information",
    bscInfo: "BSC wallet information",
    Balance: "DPR Balance",
    transferInfo: "Transfer information",
    DeeperChain: "Deeper Chain Wallet",
    inputDeeperChain: "Please enter the DeeperChain wallet address",
    ethAddress: "ETH wallet",
    inputEthAddress: "Please enter ETH wallet address",
    bscAddress: "BSC wallet",
    inputBscAddress: "Please enter BSC wallet address",
    Send: "Send",
    SendTips:
      "The maximum cross-chain transfer per direction across the entire network is 1,000,000 DPR every day.",
    SendTips2: "Each swap requires 12 block verifications on the network.",
    deeperChainInfo: "DeeperChain wallet information",
    withdraw: "Withdraw",
    time: "time",
    direction: "direction",
    sender: "sender",
    recipient: "recipient",
    amount: "amount",
    status: "status",
    success: "success",
    pending: "pending",
    withdrawed: "withdrawed",
    recordList: "Transfer records",
    error: "error",
    noPolkadot:
      "Please install the polkadot{.js} extension chrome plug-in and import the wallet.",
    noMetamask: "Please install the Metamask",
    testnetError:
      "You are using the testnet network, please switch to the mainnet network",
    usedToday: "has been used during this period",
    max: "max",
    min: "min",
  },
  "Ethereum Bridge": "Ethereum Bridge",
  recommendBSC:
    "Click the button below to switch to the BSC or Solana network, which offer lower transaction fees.",
  bscURL: "https://docs.binance.org/smart-chain/wallet/metamask.html",
};
