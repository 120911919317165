import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router)

const routes = new Router({
  mode: 'history',
  routes: [{
      path: '/',
      redirect: '/swap'
    },
    {
      path: '/swap',
      component: () => import('@/containers/Swap/Swap'),
      name: 'Swap',
    }
  ]
})

export default routes