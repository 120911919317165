
import axios from 'axios';
import Vue from 'vue';

const fetch = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://api-device.deeper.network/' : 'http://localhost:7001/',
  timeout: 30000
})

const devFetch = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://api-device-dev.deeper.network/' : 'http://localhost:7001/',
  timeout: 30000
})

fetch.interceptors.response.use(function (response) {
  if (response.data.success) {
    return response.data
  }
  Vue.prototype.$Notify({ type: 'danger', message: window.$$i18n.t(`common.${response.data.reason}`) });
  return Promise.reject(response.data.reason);
}, function (error) {
  Vue.prototype.$Notify({ type: 'danger', message: 'unknow error' });
  return Promise.reject(error);
});
devFetch.interceptors.response.use(function (response) {
  if (response.data.success) {
    return response.data
  }
  Vue.prototype.$Notify({ type: 'danger', message: window.$$i18n.t(`common.${response.data.reason}`) });
  return Promise.reject(response.data.reason);
}, function (error) {
  Vue.prototype.$Notify({ type: 'danger', message: 'unknow error' });
  return Promise.reject(error);
});
console.log(fetch)
export default function() {
  if (window.$chainID == '42') {
    return devFetch(...arguments)
  }
  return fetch(...arguments)
}

